import { lighten } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AppNavigationStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;

  .ant-menu-item {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 10px 0 !important; // Remove default padding

    &.ant-menu-item-selected {
      background-color: ${({ theme }) =>
        lighten(0.4, theme.colors.accent)} !important;
      color: ${({ theme }) => theme.colors.accent}; !important;
    }
  }

  // Optional: remove margin if you want items truly edge-to-edge
  .ant-menu-item::after {
    margin: 0 !important;
    display: none;
  }
`
