import { Layout, Grid, Row, Col, Flex, Space } from 'antd'
import * as React from 'react'

import { DetailViewLayoutStyled } from './styles'

import { Content } from 'antd/lib/layout/layout'

export interface DetailViewLayoutProps {
  header?: React.ReactNode
  children: React.ReactNode
}

const { useBreakpoint } = Grid

export const DetailViewLayout = ({
  header,
  children,
  ...props
}: DetailViewLayoutProps) => {
  const screens = useBreakpoint()

  const viewPaddingStyles = {
    ...((screens.xs || screens.sm) && { padding: '1em' }),
    ...((screens.md || screens.lg) && { padding: '2em' }), // To support full screen width buttons
  }
  const headerStyles = { marginBottom: '1em' }
  const contentStyles = {
    background: '#fff',
    padding: '24px',
    ...((screens.xs || screens.sm) && { margin: '0.5em 0 2em 0' }),
    ...((screens.md || screens.lg) && { margin: '2em 0' }),
  }

  return (
    <DetailViewLayoutStyled>
      <Layout style={viewPaddingStyles}>
        {header && <Content style={headerStyles}>{header}</Content>}
        <Content style={contentStyles}>{children}</Content>
      </Layout>
    </DetailViewLayoutStyled>
  )
}

DetailViewLayout.displayName = 'DetailViewLayout'
