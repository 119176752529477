import { rem, tint, lighten } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialsListStyled = styled.div`
  font-size: ${rem(16)};

  .selectable {
    cursor: pointer;
  }

  .selected {
    background-color: ${(props) => tint(0.9, props.theme.colors.primary)};

    &:hover td {
      background-color: ${(props) =>
        tint(0.9, props.theme.colors.primary)} !important;
    }
  }

  .list-header {
    opacity: 0.5;
  }

  .list-item {
    // border-bottom: 1px solid
    //   ${(props) => lighten(0.4, props.theme.colors.border)};
    &:last-child {
      border-bottom: none;
    }
  }

  .list-header,
  .list-item-cell {
    &.total {
      text-align: right;
    }

    &.quantity,
    &.markup,
    &.price {
      text-align: center;
    }
  }
`
export const MaterialsListMobileStyled = styled.div`
  font-size: ${rem(16)};
`
