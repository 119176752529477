import { List, Flex, Typography, Space, Row, Col } from 'antd'
import * as React from 'react'

import { MaterialsListItemMobileStyled } from './styles'

export interface MaterialsListItemMobileProps {
  title?: React.ReactNode
  total?: React.ReactNode
  metaInfo?: { label: string; value: any }[]
  actions?: React.ReactNode[]
}

export const MaterialsListItemMobile = ({
  title,
  total,
  metaInfo = [],
  actions = [],
}: MaterialsListItemMobileProps) => {
  return (
    <List.Item style={{ width: '100%' }}>
      <MaterialsListItemMobileStyled>
        <Row className='item-title' gutter={16}>
          <Col span={24}>
            <Flex className='item-total' justify='space-between'>
              {title}
              <Space>
                {actions.map((action, index) => (
                  <React.Fragment key={index}>{action}</React.Fragment>
                ))}
              </Space>
            </Flex>
          </Col>
        </Row>

        <Row className='item-meta' gutter={[0, 12]} style={{ width: '100%' }}>
          {metaInfo.map(({ label, value }, index) => (
            <Col span={24} key={index}>
              <Flex
                justify='space-between'
                style={{ marginTop: '8px', borderBottom: '1px solid #f5f5f5' }}
              >
                <Typography.Text>{label}</Typography.Text>
                <Typography.Text>{value}</Typography.Text>
              </Flex>
            </Col>
          ))}
        </Row>

        {!!total && (
          <Row style={{ marginTop: '12px' }}>
            <Col span={24}>
              <Flex className='item-total' align='center' justify='flex-end'>
                <Typography.Text>{total}</Typography.Text>
              </Flex>
            </Col>
          </Row>
        )}
      </MaterialsListItemMobileStyled>
    </List.Item>
  )
}

MaterialsListItemMobile.displayName = 'MaterialsListItemMobile'
