import { Flag } from 'react-feather'
import { Alert, Button, Typography, Space } from 'antd'
import * as React from 'react'

import { useChangeOrganizationModeMutation } from '../../graphql/_generated-hooks'
import { theme } from '../../styles/themes/default'
import { DemoModeBannerStyled, DemoMobileBannerStyled } from './styles'

import { PageBanner } from '../../components/PageBanner'

export interface DemoModeBannerProps {
  mobile?: boolean
  showBanner?: boolean
  onLivemodeChange?: () => void
}

export const DemoModeBanner = ({
  mobile,
  showBanner,
  onLivemodeChange,
}: DemoModeBannerProps) => {
  const [
    changeOrganizationModeMutation,
    { loading: isChangingOrganizationMode },
  ] = useChangeOrganizationModeMutation()

  const handleOnSetOrganizationLivemode = () => {
    changeOrganizationModeMutation({
      variables: {
        input: {
          livemode: true,
        },
      },
      onCompleted() {
        onLivemodeChange?.()
      },
    })
  }

  const renderMobileBanner = () => (
    <DemoMobileBannerStyled>
      <Space>
        <Typography.Text>
          You are currently exploring VoltPro with demonstration data.
        </Typography.Text>
        <Button
          size='small'
          type='primary'
          onClick={handleOnSetOrganizationLivemode}
          loading={isChangingOrganizationMode}
        >
          Go Live
        </Button>
      </Space>
    </DemoMobileBannerStyled>
  )
  const renderDesktopBanner = () => (
    <PageBanner
      icon={<Flag color={theme.colors.yellow} />}
      message='Welcome to Demo Mode!'
      description={`You're currently exploring VoltPro with demonstration data. Ready to start with your own data? Clear the demo data and go live.`}
      type='warning'
      action={
        <Button
          type='primary'
          onClick={handleOnSetOrganizationLivemode}
          loading={isChangingOrganizationMode}
        >
          Go Live & Clear Demo Data
        </Button>
      }
    />
  )

  return (
    <DemoModeBannerStyled>
      {showBanner && mobile && renderMobileBanner()}
      {showBanner && !mobile && renderDesktopBanner()}
    </DemoModeBannerStyled>
  )
}

DemoModeBanner.displayName = 'DemoModeBanner'
