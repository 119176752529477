import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const SupportContactButtonStyled = styled.div`
  font-size: ${rem(16)};

  .support-contact-btn {
    display: flex;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.white};
    text-align: center;
    margin-top: 3em;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    a svg {
      margin-right: 8px;
    }
  }
`
