import { LoadingOutlined } from '@ant-design/icons'
import { message, Form } from 'antd'
import {
  DrawerForm,
  ProForm,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components'
import * as React from 'react'
import debounce from 'lodash/debounce'

import { theme } from '../../styles/themes/default'
import { CategoryCreateFormDrawerStyled } from './styles'
import { capitalizeString, setApiFormErrors } from '../../utils'
import { useCreateCategoryMutation } from '../../graphql/_generated-hooks'

const DRAWER_WIDTH = 450

export interface CategoryCreateFormDrawerProps {
  onClose?: () => void
  onSave?: (data: any) => void
  open?: boolean
  mode?: 'create' | 'edit'
  isDemo?: boolean
  width?: number
}

export interface CategoryFormSchema {
  id: string
  name: string
  description: string
}
export const CategoryCreateFormDrawer = ({
  onSave = (data: any) => {},
  onClose = () => {},
  open,
  mode = 'create',
  isDemo = false,
  width = DRAWER_WIDTH,
}: CategoryCreateFormDrawerProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()

  const [createCategory, { loading: createCategoryLoading }] =
    useCreateCategoryMutation()

  const isCreateMode = () => mode === 'create'

  const handleOnFinish = async (values: CategoryFormSchema) => {
    createCategory({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted({ createCategory }: any = {}) {
        messageApi.success('Success: Category created successfully!')

        onSave(createCategory?.data)
      },
      onError(error, clientOptions) {
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }

  return (
    <CategoryCreateFormDrawerStyled>
      {contextHolder}
      <DrawerForm<CategoryFormSchema>
        id='category-form'
        title={`${capitalizeString(mode)} category`}
        open={open}
        form={form}
        autoFocusFirstInput
        drawerProps={{
          getContainer: false, // To supress form render warning(https://stackoverflow.com/questions/61056421/warning-instance-created-by-useform-is-not-connect-to-any-form-element)
          destroyOnClose: true,
          maskClosable: false,
          onClose: () => {
            onClose()
          },
          width,
        }}
        submitter={{
          searchConfig: {
            submitText: 'Create',
            resetText: 'Cancel',
          },
          submitButtonProps: {
            loading: createCategoryLoading,
            id: 'category-form-submit-button',
          },
        }}
        onFinish={handleOnFinish}
      >
        <ProForm.Group>
          <ProFormText
            fieldProps={{
              'data-1p-ignore': '',
            }}
            name={'name'}
            width='xl'
            rules={[
              {
                required: true,
              },
            ]}
            label='Name'
            placeholder='Panelboard Terminations'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormTextArea
            name={'description'}
            width='xl'
            label='Description'
            placeholder='Two Pole Circuit Breakers'
          />
        </ProForm.Group>
      </DrawerForm>
    </CategoryCreateFormDrawerStyled>
  )
}

CategoryCreateFormDrawer.displayName = 'CategoryCreateFormDrawer'
