import { Grid, Row, Col, Flex, Space } from 'antd'
import * as React from 'react'

import { ViewHeaderStyled } from './styles'

import { DemoItemTag } from '../../../components/DemoItemTag'
import { Logo } from '../../../components/Logo'

export interface ViewHeaderProps {
  pageTitle: string | React.ReactNode
  actionButtoms?: React.ReactNode
  showDemoBadge?: boolean
  showLogo?: boolean
  hideButtons?: boolean
  isLoading?: boolean
}

const { useBreakpoint } = Grid

export const ViewHeader = ({
  pageTitle,
  actionButtoms,
  showDemoBadge,
  showLogo,
  hideButtons,
  isLoading,
}: ViewHeaderProps) => {
  const screens = useBreakpoint()

  const actionButtonStyles = {
    ...((screens.xs || screens.sm) && { width: '100%' }),
    ...((screens.md || screens.lg) && { width: 'auto' }), // To support full screen width buttons
  }

  return (
    <ViewHeaderStyled>
      <Row align='middle' gutter={[12, 24]}>
        <Col xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '1 0' }}>
          <Space direction='vertical'>
            <Space.Compact block>
              {showLogo && <Logo mode='mobile' />}
              {pageTitle}
            </Space.Compact>
            {showDemoBadge && <DemoItemTag />}
          </Space>
        </Col>
        {!isLoading && (
          <Col xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '1 0' }}>
            <>
              {!hideButtons && (
                <Flex justify='flex-end' align='center'>
                  <span style={actionButtonStyles}>{actionButtoms}</span>
                </Flex>
              )}
            </>
          </Col>
        )}
      </Row>
    </ViewHeaderStyled>
  )
}

ViewHeader.displayName = 'ViewHeader'
