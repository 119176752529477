import { MessageCircle } from 'react-feather'
import { Space, Typography } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { SupportContactButtonStyled } from './styles'
import { capitalizeString } from '../../utils'

export interface SupportContactButtonProps {
  status?: string
}

const defaultColor = theme.colors.lightGrey
const colorMap = {
  draft: theme.colors.yellow,
  open: theme.colors.blue,
  paid: theme.colors.green,
  uncollectible: theme.colors.red,
  void: theme.colors.red,

  // Price freshness TODO: Abstract
  new: theme.colors.green,
  recent: theme.colors.yellow,
  stale: theme.colors.red,
  none: theme.colors.lightGrey,
}

export const SupportContactButton = ({
  status = 'Default',
}: SupportContactButtonProps) => {
  return (
    <SupportContactButtonStyled>
      <div className='support-contact-btn'>
        <Space direction='vertical'>
          <a href='mailto:contact@getvoltpro.com'>
            <MessageCircle size={14} />
            <Typography.Text>Contact support</Typography.Text>
          </a>
        </Space>
      </div>
    </SupportContactButtonStyled>
  )
}

SupportContactButton.displayName = 'SupportContactButton'
