import { HolderOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Table, Form } from 'antd'
import * as React from 'react'

import type { TableColumnsType, FormInstance } from 'antd'

import { theme } from '../../../styles/themes/default'
import { formatCentsToDollars, filterArrayByObjId } from '../../../utils'
import { useAppStore } from '../../../stores/appStore'
import { EstimatesMaterialsFormTable } from './EstimatesMaterialsFormTable'
import { EstimatesMaterialsFormList } from './EstimatesMaterialsFormList'
import { EstimatesMaterialsFormStyled } from './styles'
import { format } from 'path'
import { last } from 'lodash'

export interface EstimatesMaterialsFormProps {
  mobile?: boolean
  form?: FormInstance
  disableMarkup?: boolean
  initialMaterials?: any[]
  appliedAssembly?: any
  inlineCreatedMaterials?: {
    [key: string]: { id: string; prices: { id: string; unit_amount: number }[] }
  }
  onMaterialsChange?: (newData: any) => void
  onCreateNewMaterialClick?: (record: any) => void
  onAddAssemblyClick?: () => void
}

const formatMaterialsForTable = (
  materials: any,
  keyFn?: (index: number) => number
) => {
  return materials.map((material: any, index: number) => {
    return {
      ...material,
      key: keyFn ? keyFn(index) : Math.random().toString(36), // + 1 Allow for first item sort
      name: { label: material.name, value: material.id }, // Format value in label
      custom: material.name && !material.id, // Custom material
    }
  })
}

const getNameValue = (name: any) => {
  if (typeof name === 'string') return name

  return !name?.value ? name?.label : ''
}

export const EstimatesMaterialsForm = ({
  mobile,
  form = {} as FormInstance,
  disableMarkup,
  initialMaterials,
  appliedAssembly,
  inlineCreatedMaterials,
  onMaterialsChange,
  onCreateNewMaterialClick,
  onAddAssemblyClick,
}: EstimatesMaterialsFormProps) => {
  const [formattedDataSource, setFormattedDataSource] = React.useState<any[]>(
    []
  )
  const [formattedAppliedAssembly, setFormattedAppliedAssembly] =
    React.useState<any[]>([])
  const [lastAssemblyId, setLastAssemblyId] = React.useState<string>('')

  const setEstimateFormErrors = useAppStore(
    (state: any) => state.setEstimateFormErrors
  )
  const estimateFormErrors = useAppStore(
    (state: any) => state.estimateFormErrors
  )

  // Data coming in
  React.useEffect(() => {
    if (initialMaterials && initialMaterials.length) {
      const formattedMaterials = formatMaterialsForTable(initialMaterials)

      setFormattedDataSource(formattedMaterials)
    }
  }, [initialMaterials])

  React.useEffect(() => {
    if (appliedAssembly && appliedAssembly.length) {
      // Prevent duplicate assemblies from being added to the materials
      if (lastAssemblyId && lastAssemblyId === appliedAssembly[0].id) return

      const formattedAssembly = formatMaterialsForTable(appliedAssembly)

      setFormattedAppliedAssembly(formattedAssembly)
      setLastAssemblyId(appliedAssembly[0].id)
    }
  }, [appliedAssembly])

  // Formatting data going out
  const handleUpdateFormWithValues = (newData: any, row: any) => {
    const formatMaterialsForForm = newData.map(
      (material: any, index: number) => {
        const { name } = material
        return {
          ...material,
          productId: material?.name?.value,
          name: getNameValue(name),
          metadata: { ...material?.metadata, sequence: index },
        }
      }
    )

    // Clear errors
    if (!newData.length) setEstimateFormErrors({})
    if (estimateFormErrors && row && estimateFormErrors[row.key]) {
      const errorCopy = { ...estimateFormErrors }
      delete errorCopy[row.key]
      setEstimateFormErrors(errorCopy)
    }

    form.setFieldValue('materials', formatMaterialsForForm)
    onMaterialsChange && onMaterialsChange(formatMaterialsForForm)
  }
  const handleOnCreateNewMaterialClick = (record: any) => {
    onCreateNewMaterialClick &&
      onCreateNewMaterialClick({
        ...record,
        name: record.name.label,
        unit_amount: formatCentsToDollars(record.price),
      })
  }
  const handleOnInputError = (errors: any, record: any) => {
    setEstimateFormErrors({ [record.id || record.key]: errors })
  }

  return (
    <EstimatesMaterialsFormStyled>
      <Form.Item name='materials'>
        {mobile ? (
          <EstimatesMaterialsFormList
            initialData={formattedDataSource}
            appliedAssembly={formattedAppliedAssembly}
            inlineCreatedMaterials={inlineCreatedMaterials}
            disableMarkup={disableMarkup}
            onMaterialsChange={handleUpdateFormWithValues}
            onCreateNewMaterialClick={handleOnCreateNewMaterialClick}
            onAddAssemblyClick={onAddAssemblyClick}
            onInputError={handleOnInputError}
          />
        ) : (
          <EstimatesMaterialsFormTable
            initialData={formattedDataSource}
            appliedAssembly={formattedAppliedAssembly}
            inlineCreatedMaterials={inlineCreatedMaterials}
            disableMarkup={disableMarkup}
            onMaterialsChange={handleUpdateFormWithValues}
            onCreateNewMaterialClick={handleOnCreateNewMaterialClick}
            onAddAssemblyClick={onAddAssemblyClick}
            onInputError={handleOnInputError}
          />
        )}
      </Form.Item>
    </EstimatesMaterialsFormStyled>
  )
}

EstimatesMaterialsForm.displayName = 'EstimatesMaterialsForm'
