import {
  User,
  Tool,
  Home,
  DollarSign,
  LogOut,
  Zap,
  MessageCircle,
} from 'react-feather'
import { useClerk } from '@clerk/clerk-react'
import type { MenuProps } from 'antd'
import { Layout, Button, Flex, Typography, Menu } from 'antd'
import * as React from 'react'
import { NavLink, useLocation, Outlet, useNavigate } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'

const { Sider, Content } = Layout

import { PreferenceViewMobileStyled } from './styles'

import { ViewHeader } from '../shared/ViewHeader'
import { PreferenceOrganizationView } from '../PreferenceOrganizationView'
import { PreferenceBillingView } from '../PreferenceBillingView'
import { CurrentPlanInfoBox } from '../../components/CurrentPlanInfoBox'
import { SupportContactButton } from '../../components/SupportContactButton'
import { Logo } from '../../components/Logo'

export interface PreferenceViewMobileProps {
  me?: any
  redirectObj?: any
  activePlan?: any
  materialUsage?: any
  estimateUsage?: any
}

export const PreferenceViewMobile = ({
  me,
  redirectObj,
  activePlan,
  materialUsage,
  estimateUsage,
  ...props
}: PreferenceViewMobileProps) => {
  const [current, setCurrent] = React.useState<string[]>([])
  const { signOut } = useClerk()
  const location = useLocation()
  const navigate = useNavigate()

  const isExperimentalOrg = useAppStore((state: any) => state.isExperimental)

  React.useEffect(() => {
    if (location.pathname) {
      const snippets = location.pathname.split('/').filter((i) => i)
      if (snippets.length > 0) {
        setCurrent([snippets.join('/')])
      }
    }
  }, [location])

  return (
    <PreferenceViewMobileStyled>
      <div className='sticky-search'>
        <Flex justify='space-around'>
          <Logo mode='mobile' />
        </Flex>
      </div>

      <PreferenceOrganizationView me={me} redirectObj={redirectObj} />
      <PreferenceBillingView me={me} redirectObj={redirectObj} mobile={true} />
      <CurrentPlanInfoBox
        plan={activePlan}
        materialCount={materialUsage}
        materialTotal={me?.organization?.materialLimit || 0}
        estimateCount={estimateUsage}
        estimateTotal={me?.organization?.estimateLimit || 0}
      />
      <div style={{ padding: '0 2em 2em 2em' }}>
        <SupportContactButton />
        <Button
          icon={<LogOut size={16} />}
          onClick={() => {
            signOut(() => {
              navigate('/')
            })
          }}
          block
          style={{ marginTop: '1em' }}
        >
          Logout
        </Button>
      </div>
    </PreferenceViewMobileStyled>
  )
}

PreferenceViewMobile.displayName = 'PreferenceViewMobile'
