import {
  PlusOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'
import {
  message,
  Button,
  Col,
  Input,
  Row,
  Pagination,
  Space,
  List,
  Divider,
  Flex,
  Typography,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'

import {
  useGetCustomersQuery,
  useBulkImportCustomersFromQuickbooksMutation,
} from '../../graphql/_generated-hooks'

import { ViewHeader } from '../shared/ViewHeader'
import { ListViewLayout } from '../shared/ListViewLayout'

import { PageTitle } from '../../components/PageTitle'
import { CustomersTable } from '../../components/CustomersTable'
import { CustomerFormDrawer } from '../../components/CustomerFormDrawer'
import { QuickbooksBulkSyncModal } from '../../components/QuickbooksBulkSyncModal'
import { LockableButton } from '../../components/LockableButton'
import { QuickbooksIcon } from '../../components/QuickbooksIcon'
import { Logo } from '../../components/Logo'
import { CustomerListViewStyled } from './styles'

const { Search } = Input
const defaultPageSize = 10
const lockReason = 'You need to upgrade to import customers'

export interface CustomerListViewProps {
  mobile?: boolean
}

export const CustomerListView = ({
  mobile,
  ...props
}: CustomerListViewProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [currentPagination, setCurrentPagination] = React.useState<any>(1)
  const [createCustomer, setCreateCustomer] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState<any>(null)
  const [customerData, setCustomerData] = React.useState<any>([])
  const [showQuickbooksBulkSyncModal, setShowQuickbooksBulkSyncModal] =
    React.useState(false)

  const activePlan = useAppStore((state: any) => state.plan)
  const quickbooksIntegrationConfig = useAppStore(
    (state: any) => state.quickbooksConfig
  )

  let navigate = useNavigate()

  const {
    data: { customers = { data: [], count: 0 } } = {
      customers: { data: [], count: 0 },
    },
    loading: isLoadingCustomers,
    refetch: refetchCustomers,
    updateQuery: updateCustomers,
  } = useGetCustomersQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: { search: '' },
    },
    onCompleted: ({ customers }) => {
      setCustomerData(customers?.data || [])
    },
  })

  const handleAddCustomer = () => {
    setCreateCustomer(true)
  }
  const handleCustomerFormClose = () => {
    setCreateCustomer(false)
  }

  const handleRowClick = (row: any, event: any) => {
    event.preventDefault()
    navigate(`/customers/${row.id}`, {})
  }

  const onSearch = (value: string) => {
    setSearchQuery(value)
    refetchCustomers({
      input: {
        limit: defaultPageSize,
        search: value,
      },
    })
  }
  const onSort = (field: string, order: string) => {
    refetchCustomers({
      // @ts-ignore
      input: {
        limit: defaultPageSize,
        ...(order && {
          sort: {
            field,
            order: 'ascend' == order ? 'desc' : 'asc',
          },
        }),
      },
    })
  }
  const onPageChange = (page: number, pageSize?: number) => {
    setCurrentPagination(page)
    refetchCustomers({
      input: {
        limit: pageSize,
        offset: page * pageSize! - pageSize!,
        ...(searchQuery && { search: searchQuery }),
      },
    })
  }
  const handleShouldLockImportCustomers = () => {
    return !activePlan
  }
  const handleBulkImportCustomers = () => {
    setShowQuickbooksBulkSyncModal(true)
  }
  const handleBulkImportCustomersSuccess = (stats: any) => {
    setShowQuickbooksBulkSyncModal(false)

    if (stats.imported) {
      messageApi.success(
        `Success: ${stats.imported} customers imported successfully!`
      )
    } else {
      messageApi.success(`Success: Customers imported successfully!`)
    }

    refetchCustomers({
      input: {
        limit: defaultPageSize,
      },
    })
  }
  const canImportCustomers = () => {
    return quickbooksIntegrationConfig?.enabled
  }

  const renderMobileView = () => (
    <Content>
      {/* Mobile Search/Filter Header */}
      <div className='sticky-search'>
        <Space.Compact className='full-width'>
          <Logo mode='mobile' />
          <Search
            id='material-search-input'
            placeholder='Search'
            size='large'
            defaultValue={searchQuery}
            onSearch={onSearch}
            onChange={debounce((e) => {
              const value = e.target.value
              if (value && value.length > 1) {
                onSearch(value)
              } else if (value === '') {
                onSearch('')
              }
            }, 300)}
            allowClear={true}
          />
        </Space.Compact>
      </div>

      {/* Mobile List View */}
      <div style={{ padding: '1em' }}>
        <ViewHeader
          pageTitle={
            <PageTitle title={`Customers`} subtitle='Manage customers.' />
          }
          actionButtoms={
            <Button
              size='large'
              onClick={handleAddCustomer}
              data-testid='add-customer-button'
              style={{ width: '100%' }}
            >
              <PlusOutlined />
              New Customer
            </Button>
          }
        />
        <Divider />

        <List
          loading={isLoadingCustomers}
          dataSource={customerData}
          renderItem={({ id, name, email, phone, createdAt }: any) => (
            <List.Item
              onClick={() => navigate(`/customers/${id}`)}
              className='cursor-pointer'
            >
              <List.Item.Meta
                title={`${name}`}
                description={
                  <Space direction='vertical' size='small'>
                    <Typography.Text type='secondary'>
                      {email || '-'}
                    </Typography.Text>
                  </Space>
                }
                style={{ paddingRight: '1em' }}
              />
            </List.Item>
          )}
        />

        {!!customers?.count && customers?.count > customerData.length && (
          <Flex
            style={{
              width: '100%',
              marginTop: '2em',
            }}
            justify={'space-between'}
            align={'center'}
          >
            <Button
              icon={<LeftCircleOutlined />}
              disabled={currentPagination <= 1}
              onClick={() =>
                onPageChange(currentPagination - 1, defaultPageSize)
              }
            >
              Prev
            </Button>
            <Button
              icon={<RightCircleOutlined />}
              iconPosition='end'
              onClick={() =>
                onPageChange(currentPagination + 1, defaultPageSize)
              }
            >
              Next
            </Button>
          </Flex>
        )}
      </div>

      <CustomerFormDrawer
        open={createCustomer}
        onClose={handleCustomerFormClose}
        onCustomerSave={() => {
          refetchCustomers()
          handleCustomerFormClose()
        }}
      />
    </Content>
  )
  const renderDesktopView = () => (
    <CustomerListViewStyled>
      {contextHolder}

      <ListViewLayout
        header={
          <ViewHeader
            pageTitle={
              <PageTitle title={`Customers`} subtitle='Manage customers.' />
            }
            actionButtoms={
              <Space>
                {canImportCustomers() && (
                  <Button
                    icon={<QuickbooksIcon />}
                    onClick={handleBulkImportCustomers}
                    data-testid='upload-material-button'
                  >
                    Import Customers
                  </Button>
                )}
                <Button
                  onClick={handleAddCustomer}
                  data-testid='add-customer-button'
                >
                  <PlusOutlined />
                  New Customer
                </Button>
              </Space>
            }
          />
        }
        search={
          <Search
            placeholder='Search'
            onSearch={onSearch}
            onChange={debounce((e) => {
              const value = e.target.value
              if (value && value.length > 1) {
                onSearch(value)
              } else if (value === '') {
                onSearch('')
              }
            }, 300)}
            allowClear={true}
            style={{ width: 350 }}
            data-testid='customer-search-input'
          />
        }
        pagination={
          <Pagination
            defaultCurrent={1}
            defaultPageSize={defaultPageSize}
            showSizeChanger={false}
            total={customers?.count}
            onChange={onPageChange}
            style={{ float: 'right' }}
          />
        }
      >
        <CustomersTable
          data={customerData}
          tableProps={{ loading: isLoadingCustomers }}
          onCustomerClick={handleRowClick}
          onCustomerDelete={() => {}}
          onChange={debounce((pagination, filters, sorter) => {
            onSort(sorter.field, sorter.order)
          }, 400)}
          selectedId={''}
        />
      </ListViewLayout>

      <CustomerFormDrawer
        open={createCustomer}
        onClose={handleCustomerFormClose}
        onCustomerSave={() => {
          refetchCustomers()
          handleCustomerFormClose()
        }}
      />

      <QuickbooksBulkSyncModal
        requiresUpgrade={handleShouldLockImportCustomers()}
        open={showQuickbooksBulkSyncModal}
        syncType='customers'
        onCancel={() => setShowQuickbooksBulkSyncModal(false)}
        onBulkSyncSuccess={handleBulkImportCustomersSuccess}
      />
    </CustomerListViewStyled>
  )

  return mobile ? renderMobileView() : renderDesktopView()
}

CustomerListView.displayName = 'CustomerListView'
