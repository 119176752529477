import {
  DeleteOutlined,
  PlusOutlined,
  FontColorsOutlined,
  MoreOutlined,
  ArrowUpOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import { Row, Col, Button, Dropdown, Space } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { ListButtonBarStyled } from './styles'
export interface ListButtonBarProps {
  showClearAll?: boolean
  onAddClick?: () => void
  onClearAllClick?: () => void
  dropdownButtonItems?: any[]
  fullWidth?: boolean
}

export const ListButtonBar = ({
  showClearAll = false,
  onAddClick = () => {},
  onClearAllClick = () => {},
  dropdownButtonItems,
  fullWidth,
  ...props
}: ListButtonBarProps) => {
  return (
    <ListButtonBarStyled>
      <Space.Compact size={fullWidth ? 'large' : 'middle'} block={fullWidth}>
        {!dropdownButtonItems && <Button onClick={onAddClick}>Add item</Button>}

        {dropdownButtonItems && (
          <Dropdown.Button
            onClick={onAddClick}
            menu={{ items: dropdownButtonItems }}
            data-testid='list-add-item-more-button'
          >
            Add item
          </Dropdown.Button>
        )}
        {showClearAll && (
          <Button
            onClick={onClearAllClick}
            block
            danger
            data-testid='list-clear-all-items-button'
          >
            Clear All
          </Button>
        )}
      </Space.Compact>
    </ListButtonBarStyled>
  )
}

ListButtonBar.displayName = 'ListButtonBar'
