import {
  PlusOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'
import {
  Col,
  Input,
  Row,
  Space,
  Pagination,
  Divider,
  List,
  Flex,
  Typography,
  Button,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { useNavigate, Link } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'
import { isLimitReached, formatDate, formatMoney } from '../../utils'

import { useGetEstimatesQuery } from '../../graphql/_generated-hooks'

import { ViewHeader } from '../shared/ViewHeader'
import { ListViewLayout } from '../shared/ListViewLayout'

import { Logo } from '../../components/Logo'
import { PageTitle } from '../../components/PageTitle'
import { EstimatesTable } from '../../components/EstimatesTable'
import { LockableButton } from '../../components/LockableButton'
import { EstimateDuplicateModal } from '../../components/EstimateDuplicateModal'
import { EstimateListViewStyled } from './styles'

const { Search } = Input
const defaultPageSize = 10
const DEMO_ESTIMATE_LIMIT = 2
const ESTIMATE_LIMIT_REASONS = {
  PAID: 'You have reached your monthly estimate limit on this plan. Upgrade to create more estimates.',
  DEMO: 'You have reached the maximum number of estimates allowed in demo mode.',
} as const

export interface EstimateListViewProps {
  me?: any
  mobile?: boolean
}

export const EstimateListView = ({
  me,
  mobile,
  ...props
}: EstimateListViewProps) => {
  const [searchQuery, setSearchQuery] = React.useState<any>(null)
  const [estimateData, setEstimateData] = React.useState<any>([])
  const [currentPagination, setCurrentPagination] = React.useState<any>(1)
  const [showDuplicateModal, setShowDuplicateModal] = React.useState<any>(false)
  const [estimateToDuplicate, setEstimateToDuplicate] =
    React.useState<any>(false)

  let navigate = useNavigate()
  const estimateUsage = useAppStore((state: any) => state.estimates)
  const activePlan = useAppStore((state: any) => state.plan)

  const {
    data: { estimates = { data: [], count: 0 } } = {
      estimates: { data: [], count: 0 },
    },
    loading: isLoadingEstimates,
    refetch: refetchEstimates,
    updateQuery: updateEstimates,
  } = useGetEstimatesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: { search: '' },
    },
    onCompleted: ({ estimates }) => {
      setEstimateData(estimates?.data || [])
    },
  })

  const handleAddEstimate = () => {
    navigate(`/estimates/create`, {})
  }
  const handleGoToSettings = () => {
    navigate(`/settings/organization`)
  }

  const onSearch = (value: string) => {
    setSearchQuery(value)
    refetchEstimates({
      input: {
        limit: defaultPageSize,
        search: value,
      },
    })
  }
  const onSort = (field: string, order: string) => {
    refetchEstimates({
      // @ts-ignore
      input: {
        limit: defaultPageSize,
        ...(order && {
          sort: {
            field,
            order: 'ascend' == order ? 'desc' : 'asc',
          },
        }),
      },
    })
  }
  const onPageChange = (page: number, pageSize?: number) => {
    setCurrentPagination(page)
    refetchEstimates({
      input: {
        limit: pageSize,
        offset: page * pageSize! - pageSize!,
        ...(searchQuery && { search: searchQuery }),
      },
    })
  }
  const shouldLockCreateEstimate = React.useMemo(() => {
    const demo_mode = !me?.organization?.livemode

    if (demo_mode) {
      return isLimitReached(estimateData.length, DEMO_ESTIMATE_LIMIT, '')
    }

    return isLimitReached(
      estimateUsage,
      me?.organization?.estimateLimit,
      activePlan
    )
  }, [
    me?.organization?.livemode,
    estimateUsage,
    me?.organization?.estimateLimit,
    activePlan,
    estimateData.length,
  ])
  const getDisabledCreateEstimateReason = React.useMemo(() => {
    const demo_mode = !me?.organization?.livemode
    return demo_mode ? ESTIMATE_LIMIT_REASONS.DEMO : ESTIMATE_LIMIT_REASONS.PAID
  }, [me?.organization?.livemode])

  const renderMobileView = () => (
    <Content>
      {/* Mobile Search/Filter Header */}
      <div className='sticky-search'>
        <Space.Compact className='full-width'>
          <Logo mode='mobile' />
          <Search
            id='material-search-input'
            placeholder='Search'
            size='large'
            defaultValue={searchQuery}
            onSearch={onSearch}
            onChange={debounce((e) => {
              const value = e.target.value
              if (value && value.length > 1) {
                onSearch(value)
              } else if (value === '') {
                onSearch('')
              }
            }, 300)}
            allowClear={true}
          />
        </Space.Compact>
      </div>

      {/* Mobile List View */}
      <div style={{ padding: '1em' }}>
        <ViewHeader
          pageTitle={
            <PageTitle title={`Estimates`} subtitle='Manage estimates.' />
          }
          actionButtoms={
            <LockableButton
              size='large'
              locked={shouldLockCreateEstimate}
              reason={getDisabledCreateEstimateReason}
              onClick={handleAddEstimate}
              style={{ width: '100%' }}
            >
              <PlusOutlined />
              New Estimate
            </LockableButton>
          }
        />
        {shouldLockCreateEstimate && (
          <Typography.Text type='secondary' style={{ marginTop: '10px' }}>
            {getDisabledCreateEstimateReason}{' '}
            <a onClick={handleGoToSettings}>Go to settings</a>
          </Typography.Text>
        )}
        <Divider />

        <List
          loading={isLoadingEstimates}
          dataSource={estimateData}
          renderItem={({
            id,
            number,
            name,
            total,
            contact_name,
            createdAt,
          }: any) => (
            <List.Item onClick={() => navigate(`/estimates/${id}`)}>
              <List.Item.Meta
                title={`${number}  ${(name && ' - ' + name) || ''}`}
                description={
                  <Space direction='vertical' size='small'>
                    <Typography.Text type='secondary'>
                      {contact_name}
                    </Typography.Text>
                    <Typography.Text type='secondary'>
                      {formatDate(createdAt)}
                    </Typography.Text>
                  </Space>
                }
                style={{ paddingRight: '1em' }}
              />
              <Typography.Text strong>{formatMoney(total)}</Typography.Text>
            </List.Item>
          )}
        />

        {!!estimates?.count && estimates?.count > estimateData.length && (
          <Flex
            style={{
              width: '100%',
              marginTop: '2em',
            }}
            justify={'space-between'}
            align={'center'}
          >
            <Button
              icon={<LeftCircleOutlined />}
              disabled={currentPagination <= 1}
              onClick={() =>
                onPageChange(currentPagination - 1, defaultPageSize)
              }
            >
              Prev
            </Button>
            <Button
              icon={<RightCircleOutlined />}
              iconPosition='end'
              onClick={() =>
                onPageChange(currentPagination + 1, defaultPageSize)
              }
            >
              Next
            </Button>
          </Flex>
        )}
      </div>
    </Content>
  )

  const renderDesktopView = () => (
    <EstimateListViewStyled>
      <ListViewLayout
        header={
          <Row>
            <Col span={12}>
              <PageTitle title='Estimates' subtitle='Manage estimates' />
            </Col>
            <Col span={12} className='create-btn-container'>
              <LockableButton
                locked={shouldLockCreateEstimate}
                reason={getDisabledCreateEstimateReason}
                onClick={handleAddEstimate}
              >
                <PlusOutlined />
                New Estimate
              </LockableButton>
            </Col>
          </Row>
        }
        search={
          <Search
            placeholder='Search'
            onSearch={onSearch}
            onChange={debounce((e) => {
              const value = e.target.value
              if (value && value.length > 1) {
                onSearch(value)
              } else if (value === '') {
                onSearch('')
              }
            }, 300)}
            allowClear={true}
            style={{ width: 350, float: 'right' }}
          />
        }
        pagination={
          <Pagination
            defaultCurrent={1}
            defaultPageSize={defaultPageSize}
            showSizeChanger={false}
            total={estimates?.count}
            onChange={onPageChange}
            style={{ float: 'right' }}
          />
        }
      >
        <EstimatesTable
          data={estimateData}
          tableProps={{ loading: isLoadingEstimates }}
          allowDuplicate={!shouldLockCreateEstimate}
          onEstimateClick={() => {}}
          onEstimateDuplicate={(estimate) => {
            setEstimateToDuplicate(estimate)
            setShowDuplicateModal(true)
          }}
          onEstimateDelete={() => {}}
          onChange={debounce((pagination, filters, sorter) => {
            onSort(sorter.field, sorter.order)
          }, 400)}
          selectedId={''}
        />
      </ListViewLayout>

      <EstimateDuplicateModal
        open={showDuplicateModal}
        onCancel={() => {
          setShowDuplicateModal(false)
          setEstimateToDuplicate({})
        }}
        estimateObj={estimateToDuplicate}
      />
    </EstimateListViewStyled>
  )

  return mobile ? renderMobileView() : renderDesktopView()
}

EstimateListView.displayName = 'EstimateListView'
