import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const LogoStyled = styled.div<{ size?: string }>`
  color: ${(props) => props.theme.colors.textPrimary};
  width: ${(props) => (props.size == 'sm' ? '50px' : '175px')};
  height: 150px;
  margin-top: 15px;

  display: flex;

  align-items: center;
  justify-content: center;

  img {
    padding-top: 20px;
    width: ${(props) => (props.size == 'sm' ? '25px' : '60px')};
    height: auto;
  }
`
export const LogoMobileStyled = styled.div<{ size?: string }>`
  color: ${(props) => props.theme.colors.textPrimary};
  width: 44px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 1em;

  img {
    width: 100%;
    object-fit: contain;
  }
`
