import {
  SnippetsOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  DownloadOutlined,
  LockOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import {
  message,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Select,
  Table,
  Typography,
  Empty,
  ConfigProvider,
  Skeleton,
  Dropdown,
  Layout,
  Descriptions,
  Modal,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { Link, useParams, redirect, useNavigate } from 'react-router-dom'

import {
  useGetEstimateQuery,
  useDeleteEstimateMutation,
  EstimateDetailFragment,
} from '../../graphql/_generated-hooks'
import { useAppStore } from '../../stores/appStore'
import { formatMoney, formatDate, isLimitReached } from '../../utils'
import { demo_organization_info } from '../../config/default'
import { ViewHeader } from '../../../src/views/shared/ViewHeader'
import { DetailViewLayout } from '../../../src/views/shared/DetailViewLayout'
import { PageTitle } from '../../components/PageTitle'
import { QuickbooksIcon } from '../../components/QuickbooksIcon'
import { DemoItemTag } from '../../components/DemoItemTag'
import { MaterialsList } from '../../components/MaterialsList'
import { TaxTotalSummary } from '../../components/TaxTotalSummary'
import { AddressDisplay } from '../../components/AddressDisplay'
import { SkeletonLoader } from '../../components/SkeletonLoader'
import { PDFViewer } from '../../components/PDFViewer'
import { LogoDisplay } from '../../components/LogoDisplay'
import { RichTextRenderer } from '../../components/RichTextRenderer'
import { EstimateDuplicateModal } from '../../components/EstimateDuplicateModal'
import { EstimatePDFGenerateModal } from '../../components/EstimatePDFGenerateModal'
import { EstimateCreateQuickbooksObjModal } from '../../components/EstimateCreateQuickbooksObjModal'
import { EstimatesDetailViewStyled } from './styles'
import { InvoicesTable } from '../../components/InvoicesTable'
import { ExpandableContent } from '../../components/ExpandableContent'
import { CondintionalTooltip } from '../../components/CondintionalTooltip'
import { theme } from '../../styles/themes/default'

const { Text, Title } = Typography

export interface EstimatesDetailViewProps {
  me?: any
  mobile?: boolean
}

export const EstimatesDetailView = ({
  me,
  mobile,
  ...props
}: EstimatesDetailViewProps) => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [estimateData, setEstimateData] =
    React.useState<EstimateDetailFragment>({} as EstimateDetailFragment)
  const [organizationData, setOrganizationData] = React.useState(
    me?.organization
  )
  const [viewDocument, setViewDocument] = React.useState(false)
  const [showDuplicateModal, setShowDuplicateModal] = React.useState<any>(false)
  const [showPDFGenerateModal, setShowPDFGenerateModal] =
    React.useState<any>(false)
  const [showQuickbooksCreateModal, setShowQuickbooksCreateModal] =
    React.useState(false)
  const [deleteEstimate, { loading: deletingEstimate }] =
    useDeleteEstimateMutation()
  let { id } = useParams()
  const estimateUsage = useAppStore((state: any) => state.estimates)
  const activePlan = useAppStore((state: any) => state.plan)

  const quickbooksIntegrationConfig = useAppStore(
    (state: any) => state.quickbooksConfig
  )

  if (!id) {
    navigate('/estimates')
  }

  const isFreePlanLimitReached = isLimitReached(
    estimateUsage,
    me?.organization?.estimateLimit,
    activePlan
  )

  // Get Estimate
  const {
    data: { estimate = {} } = { estimate: { id: null } },
    loading: isLoadingEstimates,
    refetch: refetchEstimates,
    updateQuery: updateEstimatesQuery,
  } = useGetEstimateQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: id || '' },
    onCompleted: ({ estimate }) => {
      if (estimate) {
        setEstimateData(estimate)

        if (estimate.metadata?.demo) {
          setOrganizationData({ ...me.organization, ...demo_organization_info })
        }
      }
    },
  })

  const handleOpenQuickbooksCreateModal = () => {
    setShowQuickbooksCreateModal(true)
  }
  const handleOpenGeneratePDFModal = () => {
    setShowPDFGenerateModal(true)
  }
  const handleDownloadPDFModal = () => {
    window.open(`${estimateData?.estimate_pdf}?${me?.sas_token}`, '_blank')
  }
  const handleSetGeneratedPDF = (estimate_pdf: string) => {
    if (estimate_pdf) {
      // Update the distributors cache
      updateEstimatesQuery((data: any) => {
        return {
          estimate: {
            ...data.estimate,
            estimate_pdf,
          },
        }
      })

      setEstimateData({
        ...estimateData,
        estimate_pdf: estimate_pdf,
      })

      window.open(`${estimate_pdf}?${me?.sas_token}`, '_blank')
    }
  }
  const handleEditEstimate = () => {
    navigate(`/estimates/${estimateData.id}/edit`)
  }
  const handleAddLogo = () => {
    navigate(`/settings/organization?referrer=estimates&id=${estimateData.id}`)
  }
  const handlePrintOrderSheet = () => {
    navigate(`/estimates/${estimateData.id}/order-sheet`)
  }
  const canCreateInvoice = () => {
    return (
      estimateData?.job?.customers?.[0]?.externalIds?.quickbooks &&
      quickbooksIntegrationConfig?.enabled
    )
  }

  const onActionMenuClick = (e: any) => {
    const info = e.key
    switch (info) {
      case 'duplicate':
        setShowDuplicateModal(true)
        break
      case 'delete':
        Modal.confirm({
          title: `Delete ${estimateData.number}?`,
          content:
            'Are you sure you want to delete the estimate? This action cannot be undone.',
          okText: 'Confirm',
          okType: 'danger',
          cancelText: 'Cancel',
          okButtonProps: {
            loading: deletingEstimate,
          },
          onOk() {
            deleteEstimate({
              variables: {
                id: estimateData.id,
              },
            }).then(() => {
              navigate(
                `/estimates?message=${estimateData.number} has been deleted&status=success`
              )
            })
          },
        })
        break
    }
  }

  const renderEstimateButtonsDesktop = () => (
    <div style={{ textAlign: 'right' }}>
      <Space>
        <>
          {canCreateInvoice() && (
            <Button
              icon={<QuickbooksIcon />}
              onClick={handleOpenQuickbooksCreateModal}
            >
              Generate Invoice
            </Button>
          )}
          {estimateData.estimate_pdf && (
            <Dropdown.Button
              disabled={estimateData?.metadata?.demo}
              onClick={handleDownloadPDFModal}
              menu={{
                items: [
                  {
                    key: 'preview',
                    label: 'Preview PDF',
                    icon: <EyeOutlined />,
                    onClick: () => setViewDocument(true),
                  },
                  {
                    key: 'generate',
                    label: 'Regenerate PDF',
                    icon: <FilePdfOutlined />,
                    onClick: handleOpenGeneratePDFModal,
                  },
                ],
              }}
            >
              <DownloadOutlined />
              Download PDF
            </Dropdown.Button>
          )}
          {!estimateData.estimate_pdf && (
            <CondintionalTooltip
              showTooltip={estimateData?.metadata?.demo}
              title='Generating PDFs only works on live estimates'
            >
              <Button
                disabled={estimateData?.metadata?.demo}
                icon={<FilePdfOutlined />}
                onClick={handleOpenGeneratePDFModal}
              >
                Generate PDF
              </Button>
            </CondintionalTooltip>
          )}
          <Dropdown.Button
            onClick={handleEditEstimate}
            menu={{
              items: [
                {
                  key: 'order-sheet',
                  label: 'Build Order Sheet',
                  icon: <FileDoneOutlined />,
                  onClick: handlePrintOrderSheet,
                },
                {
                  key: 'duplicate',
                  label: 'Duplicate Estimate',
                  icon: !isFreePlanLimitReached ? (
                    <SnippetsOutlined />
                  ) : (
                    <LockOutlined />
                  ),
                  disabled: isFreePlanLimitReached,
                },
                {
                  key: 'delete',
                  label: 'Delete Estimate',
                  icon: <DeleteOutlined />,
                  danger: true,
                },
              ],
              onClick: onActionMenuClick,
            }}
          >
            <EditOutlined />
            Edit Estimate
          </Dropdown.Button>
        </>
      </Space>
    </div>
  )
  const renderEstimateButtonsMobile = () => (
    <>
      <Dropdown.Button
        disabled={estimateData?.metadata?.demo}
        onClick={() => {
          if (!estimateData.estimate_pdf) {
            handleOpenGeneratePDFModal()
          } else {
            handleDownloadPDFModal()
          }
        }}
        menu={{
          items: [
            {
              key: 'edit',
              label: 'Edit Estimate',
              icon: <EditOutlined />,
              onClick: handleEditEstimate,
            },
            {
              key: 'duplicate',
              label: 'Duplicate Estimate',
              icon: !isFreePlanLimitReached ? (
                <SnippetsOutlined />
              ) : (
                <LockOutlined />
              ),
              disabled: isFreePlanLimitReached,
            },
            {
              key: 'delete',
              label: 'Delete Estimate',
              icon: <DeleteOutlined />,
              danger: true,
            },
          ],
          onClick: onActionMenuClick,
        }}
        size='large'
      >
        <FilePdfOutlined />
        {!estimateData.estimate_pdf ? 'Generate' : 'Download'} PDF
      </Dropdown.Button>
    </>
  )

  return (
    <EstimatesDetailViewStyled>
      {contextHolder}
      <DetailViewLayout
        header={
          <>
            {!isLoadingEstimates && estimateData.id && (
              <ViewHeader
                pageTitle={
                  <PageTitle
                    title={`Estimate Details`}
                    subtitle={`Created on ${formatDate(
                      estimateData.createdAt
                    )}`}
                  />
                }
                actionButtoms={
                  mobile
                    ? renderEstimateButtonsMobile()
                    : renderEstimateButtonsDesktop()
                }
                showDemoBadge={estimateData?.metadata?.demo}
                showLogo={mobile}
              />
            )}
          </>
        }
      >
        <>
          {!isLoadingEstimates && !estimate?.id && (
            <>
              <Empty
                description={
                  <Space direction='vertical'>
                    <Typography.Text>{`No estimate found`}</Typography.Text>
                    <Button type='link' onClick={() => navigate('/estimates')}>
                      Back to Estimates
                    </Button>
                  </Space>
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </>
          )}

          {isLoadingEstimates && <SkeletonLoader pageType='detail' rows={10} />}

          {!isLoadingEstimates && estimate?.id && (
            <>
              <Row
                gutter={[16, { xs: 64, sm: 64, md: 24 }]}
                justify='space-between'
              >
                <Col xs={24} sm={24} md={16} order={mobile ? 2 : 1}>
                  <Title
                    level={4}
                    style={{ marginTop: 0, marginBottom: '1em' }}
                    data-testid='estimate-detail-title'
                  >
                    #{estimateData.number}
                    {estimateData.name && ` - ${estimateData.name}`}
                  </Title>
                  <Space direction='vertical'>
                    {!organizationData?.name && (
                      <Text italic>
                        Add your company name and address in{' '}
                        <a onClick={handleAddLogo}>settings</a>
                      </Text>
                    )}
                    <Text strong={true}>{organizationData?.name}</Text>
                    <AddressDisplay
                      address={{
                        address_line1: organizationData?.address_line1,
                        address_line2: organizationData?.address_line2,
                        address_city: organizationData?.address_city,
                        address_country: organizationData?.address_country,
                        address_state: organizationData?.address_state,
                        address_postal_code:
                          organizationData?.address_postal_code,
                      }}
                    />
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={8} order={mobile ? 1 : 2}>
                  <div
                    style={{
                      ...(!mobile && { float: 'right' }),
                      ...(mobile && {
                        justifyContent: 'space-evenly',
                      }),
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <LogoDisplay
                      width={200}
                      height={200}
                      logo={organizationData?.logo}
                      sasToken={
                        !estimateData?.metadata?.demo ? me?.sas_token : null
                      }
                      emptyText='Add your logo'
                      emptyDescription={
                        <a onClick={handleAddLogo}>
                          <Text
                            type='secondary'
                            style={{ color: theme.colors.blue }}
                          >
                            Go to settings
                          </Text>
                        </a>
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Divider />

              <Row gutter={[16, 24]} justify='space-between'>
                <Col xs={24} sm={24} md={6}>
                  <Descriptions layout='vertical'>
                    <Descriptions.Item label='Billing Address'>
                      <Space direction='vertical'>
                        <Text strong={true}>
                          {estimateData?.job?.customers?.[0]?.name}
                        </Text>
                        <AddressDisplay
                          address={estimateData?.job?.customers?.[0]}
                        />
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  {(estimateData?.contact_name ||
                    estimateData?.contact_email) && (
                    <Descriptions layout='vertical'>
                      <Descriptions.Item label='Contact info'>
                        <Space direction='vertical' size={'small'}>
                          <Text strong={true}>
                            {estimateData?.contact_name}
                          </Text>
                          <Text>{estimateData?.contact_email}</Text>
                        </Space>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 9, offset: 3 }}
                >
                  <Descriptions
                    column={3}
                    contentStyle={{ justifyContent: 'flex-end' }}
                  >
                    {estimateData?.estimate_date && (
                      <Descriptions.Item span={3} label='Issue date'>
                        {formatDate(estimateData?.estimate_date)}
                      </Descriptions.Item>
                    )}
                    {estimateData?.expiration_date && (
                      <Descriptions.Item span={3} label='Due date'>
                        {formatDate(estimateData?.expiration_date)}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item span={3} label='Amount due'>
                      {formatMoney(estimateData?.total)}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>

              <Divider />

              {estimateData?.work_scope && (
                <>
                  <Row gutter={[16, 24]}>
                    <Col span={24}>
                      <Title level={4}>Scope of work</Title>
                    </Col>
                    <Col span={24}>
                      <ExpandableContent maxHeight={400}>
                        <RichTextRenderer state={estimateData.work_scope} />
                      </ExpandableContent>
                    </Col>
                  </Row>

                  <Divider />
                </>
              )}

              {estimateData?.line_items &&
                estimateData?.line_items?.length > 0 && (
                  <>
                    <Row gutter={[16, 24]}>
                      <Col span={24}>
                        <Space align='baseline'>
                          <Title level={4}>Materials</Title>
                          {!estimateData?.metadata?.demo && !mobile && (
                            <Link
                              to={`/estimates/${estimateData.id}/order-sheet`}
                            >
                              (<FileDoneOutlined /> Order sheet)
                            </Link>
                          )}
                        </Space>
                      </Col>
                      <Col span={24}>
                        <MaterialsList
                          mobile={mobile}
                          data={estimateData?.line_items || []}
                          disableMarkup={!!estimateData?.custom_subtotal}
                        />
                      </Col>
                    </Row>

                    <Divider />
                  </>
                )}
              {estimateData.labor_breakdown &&
                estimateData.labor_breakdown.length > 0 && (
                  <>
                    <Row gutter={[16, 24]}>
                      <Col span={24}>
                        <Title level={4}>Labor</Title>
                      </Col>
                      <Col span={24}>
                        <Row gutter={16}>
                          <Col span={8}>
                            <p className='list-header'>Label</p>
                          </Col>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <p className='list-header'>Quantity</p>
                          </Col>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <p className='list-header'>Price</p>
                          </Col>
                          <Col
                            span={4}
                            offset={4}
                            style={{ textAlign: 'right' }}
                          >
                            <p className='list-header'>Total</p>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={8}>
                            <p>{estimateData.labor_label || 'Labor'}</p>
                          </Col>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <p>{estimateData.labor_quantity || 1}</p>
                          </Col>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <p>{formatMoney(estimateData.labor_rate)}</p>
                          </Col>
                          <Col
                            span={4}
                            offset={4}
                            style={{ textAlign: 'right' }}
                          >
                            <p>{formatMoney(estimateData.labor_total)}</p>
                          </Col>
                        </Row>
                        {estimateData.labor_breakdown?.length != 0 &&
                          estimateData.labor_breakdown?.map(
                            (breakdownItem: any, index: number) => (
                              <span key={index}>
                                {index === 0 && <Divider />}
                                <Row gutter={16} key={index}>
                                  <Col span={7} offset={1}>
                                    <p>{breakdownItem.label || 'N/A'}</p>
                                  </Col>
                                  <Col span={4} style={{ textAlign: 'center' }}>
                                    <p>{breakdownItem.quantity}</p>
                                  </Col>
                                  <Col span={4} style={{ textAlign: 'center' }}>
                                    <p>{formatMoney(breakdownItem.rate)}</p>
                                  </Col>
                                  <Col
                                    span={4}
                                    offset={4}
                                    style={{ textAlign: 'right' }}
                                  >
                                    <p>{formatMoney(breakdownItem.total)}</p>
                                  </Col>
                                </Row>
                              </span>
                            )
                          )}
                      </Col>
                    </Row>
                    <Divider />
                  </>
                )}

              <Row gutter={16} style={{ marginTop: '4em' }}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 12, offset: 12 }}
                >
                  <TaxTotalSummary
                    labor={estimateData.labor_total}
                    materials={
                      (estimateData?.custom_subtotal ||
                        estimateData?.subtotal ||
                        0) - (estimateData?.labor_total || 0)
                    }
                    currency={estimateData.currency}
                    discount={estimateData.discount_percentage}
                    taxes={estimateData.taxes}
                    total={estimateData.total}
                    subtotal={
                      estimateData?.custom_subtotal || estimateData.subtotal
                    }
                    showBreakdown={false}
                  />
                </Col>
              </Row>

              <Divider />

              {estimateData?.additional_content && (
                <>
                  <Row gutter={[16, 24]}>
                    <Col span={24}>
                      <Title level={4}>Additional content</Title>
                    </Col>
                    <Col span={24}>
                      <ExpandableContent maxHeight={200}>
                        <RichTextRenderer
                          state={estimateData.additional_content}
                        />
                      </ExpandableContent>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      </DetailViewLayout>
      {estimateData?.invoices && estimateData?.invoices?.length > 0 && (
        <Content
          style={{
            marginBottom: '2em',
            background: '#fff',
            padding: '24px',
          }}
        >
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <Space align='baseline'>
                <Title level={4}>Invoices</Title>
              </Space>
            </Col>
            <Col span={24}>
              <InvoicesTable data={estimateData?.invoices || []} />
            </Col>
          </Row>
        </Content>
      )}

      <Modal
        open={viewDocument}
        onCancel={() => setViewDocument(false)}
        footer={null}
        width={1000}
        style={{ top: 20, bottom: 20, overflow: 'scroll', textAlign: 'center' }}
      >
        <PDFViewer
          fileUrl={`${estimateData?.estimate_pdf}?${me?.sas_token}`}
          pageWidth={960}
        />
      </Modal>

      <EstimateDuplicateModal
        open={showDuplicateModal}
        onCancel={() => {
          setShowDuplicateModal(false)
        }}
        onDuplicateSuccess={() => {
          setShowDuplicateModal(false)
        }}
        estimateObj={estimateData}
      />

      <EstimatePDFGenerateModal
        estimateId={estimateData.id}
        logo={
          !estimateData?.metadata?.demo
            ? `${organizationData?.logo}?${me?.sas_token}`
            : null
        }
        open={showPDFGenerateModal}
        onCancel={() => {
          setShowPDFGenerateModal(false)
        }}
        onPDFGenerated={handleSetGeneratedPDF}
      />

      <EstimateCreateQuickbooksObjModal
        estimate={estimateData}
        singleLineItem={!!estimateData?.custom_subtotal}
        open={showQuickbooksCreateModal}
        onCancel={() => {
          setShowQuickbooksCreateModal(false)
        }}
        onCreate={() => {
          refetchEstimates()
          setShowQuickbooksCreateModal(false)
        }}
      />
    </EstimatesDetailViewStyled>
  )
}

EstimatesDetailView.displayName = 'EstimatesDetailView'
