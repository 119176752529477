import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialsListItemMobileStyled = styled.span`
  font-size: ${rem(16)};
  width: 100%;

  .item-title {
    font-weight: 600;
  }
  .item-meta {
  }
`
