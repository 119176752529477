import { rem, lighten } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const DemoModeBannerStyled = styled.div`
  font-size: ${rem(16)};
`

export const DemoMobileBannerStyled = styled.div`
  padding: 10px 1em;
  background-color: ${({ theme }) =>
    lighten(0.4, theme.colors.accent)} !important;
`
