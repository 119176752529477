import { List, Flex, Typography, Space } from 'antd'
import * as React from 'react'

import { MaterialsListItemSectionMobileStyled } from './styles'

export interface MaterialsListItemSectionMobileProps {
  title?: string | React.ReactNode
  actions?: React.ReactNode[]
}

export const MaterialsListItemSectionMobile = ({
  title,
  actions = [],
}: MaterialsListItemSectionMobileProps) => {
  return (
    <MaterialsListItemSectionMobileStyled>
      <List.Item className='section-row'>
        <Flex align='center' justify='space-between'>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
          <Space>
            {actions.map((action, index) => (
              <React.Fragment key={index}>{action}</React.Fragment>
            ))}
          </Space>
        </Flex>
      </List.Item>
    </MaterialsListItemSectionMobileStyled>
  )
}

MaterialsListItemSectionMobile.displayName = 'MaterialsListItemSectionMobile'
