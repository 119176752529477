import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  InputProps,
  message,
  Row,
  Flex,
  Spin,
  Typography,
} from 'antd'
import FormItemLabel from 'antd/lib/form/FormItemLabel'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { buildRedirectString } from '../../utils'
import {
  useGetMeQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from '../../graphql/_generated-hooks'
import { PageTitle } from '../../components/PageTitle'
import { EmailPreview } from './components/EmailPreview'
import { LandingPagePreview } from './components/LandingPagePreview'
import { UploadLogo } from '../../components/UploadLogo'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { PreferenceOrganizationViewStyled } from './styles'

export interface PreferenceOrganizationViewProps {
  me?: any
  redirectObj?: any
}

export const PreferenceOrganizationView = ({
  me,
  redirectObj,
  ...props
}: PreferenceOrganizationViewProps) => {
  const [redirectUrl, setRedirectUrl] = React.useState('')
  const navigate = useNavigate()
  const { data: { organization } = {}, loading } = useGetOrganizationQuery()
  const [updateOrganization] = useUpdateOrganizationMutation()

  React.useEffect(() => {
    if (organization) {
      form.setFieldsValue({
        ...organization,
      })

      setBrandColor(organization.color_brand || '')
    }

    if (redirectObj) {
      setRedirectUrl(buildRedirectString(redirectObj))
    }
  }, [organization, redirectObj])

  const [form] = Form.useForm()

  const initialValues = {
    logo: null,
    color_brand: '',
    color_accent: '',
  }

  const [color_brand, setBrandColor] = React.useState(initialValues.color_brand)

  const onValuesChange = ({ color_brand }: any) => {
    setBrandColor(color_brand)
  }

  const handleOnLogoRemove = () => {
    updateOrganization({
      variables: {
        input: {
          logo: null,
        },
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  const onSubmit: FormProps['onFinish'] = (values) => {
    updateOrganization({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted: () => {
        message.success('Updated successfully')
        if (redirectUrl) {
          navigate(redirectUrl)
        }
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  return (
    <PreferenceOrganizationViewStyled>
      <AnimatedComponent>
        <Content style={{ padding: '2em' }}>
          <PageTitle
            title='Company Settings'
            subtitle='Manage company settings. This will update the look and feel of estimate PDFs.'
          />

          <Spin spinning={loading}>
            <Card style={{ marginTop: '2em', maxWidth: '800px' }}>
              <Form
                form={form}
                initialValues={initialValues}
                layout='vertical'
                onValuesChange={onValuesChange}
                onFinish={onSubmit}
                scrollToFirstError
              >
                <Flex justify='space-around' style={{ marginBottom: '2em' }}>
                  <UploadLogo
                    logo={organization?.logo}
                    sasToken={me?.sas_token}
                    onRemove={handleOnLogoRemove}
                    onError={(reason) => message.error(reason)}
                  />
                </Flex>

                <Row gutter={[12, 0]}>
                  <Col span={24}>
                    <Form.Item
                      name='name'
                      label='Name'
                      rules={[
                        {
                          required: true,
                          message: 'Name is required.',
                        },
                      ]}
                    >
                      <Input data-1p-ignore />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 0]}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name='phone'
                      label='Phone'
                      rules={[
                        {
                          required: false,
                          message: 'Phone is required.',
                        },
                      ]}
                    >
                      <Input data-1p-ignore />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name='email'
                      label='Email'
                      rules={[
                        {
                          required: false,
                          message: 'Email is required.',
                        },
                      ]}
                    >
                      <Input data-1p-ignore />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name='website'
                      label='Website'
                      rules={[
                        {
                          required: false,
                          message: 'Website is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 0]}>
                  <Col span={24}>
                    <Typography.Title level={4}>Address</Typography.Title>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={'address_line1'}
                      label='Address Line 1'
                      rules={[
                        {
                          required: false,
                          message: 'Address Line 1 is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={'address_line2'}
                      label='Address Line 2'
                      rules={[
                        {
                          required: false,
                          message: 'Address Line 2 is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'address_city'}
                      label='City'
                      rules={[
                        {
                          required: false,
                          message: 'City is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'address_state'}
                      label='State/Province'
                      rules={[
                        {
                          required: false,
                          message: 'State is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'address_country'}
                      label='Country'
                      rules={[
                        {
                          required: false,
                          message: 'Country is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'address_postal_code'}
                      label='Postal Code'
                      rules={[
                        {
                          required: false,
                          message: 'Postal Code is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Typography.Title level={4}>Branding</Typography.Title>
                </Col>
                <Col>
                  <Form.Item name='color_brand' label='Brand Color'>
                    <DebouncedInput type='color' className='hex-input' />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='color_accent' label='Accent Color'>
                    <DebouncedInput type='color' className='hex-input' />
                  </Form.Item>
                </Col>
                <Row gutter={[12, 0]} style={{ paddingLeft: '6px' }}>
                  <Col>
                    <FormItemLabel prefixCls={''} label='Email' />
                    <EmailPreview color={color_brand} />
                  </Col>
                  <Col>
                    <FormItemLabel prefixCls={''} label='Landing Page' />
                    <LandingPagePreview color={color_brand} />
                  </Col>
                </Row>
              </Row> */}
                <Button
                  htmlType='submit'
                  type='primary'
                  style={{ marginTop: '2em', float: 'right' }}
                >
                  Save changes
                </Button>
              </Form>
            </Card>
          </Spin>
        </Content>
      </AnimatedComponent>
    </PreferenceOrganizationViewStyled>
  )
}

PreferenceOrganizationView.displayName = 'PreferenceOrganizationView'
