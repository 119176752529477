import { normalize } from 'polished'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  
  body, html {
    margin: 0;
    font-family: 'Lato', sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;

    background-color: ${(props) => (props.theme as any).colors.background};
    min-height: 100vh;

    color: ${(props) => (props.theme as any).colors.textPrimary};
  }

  * {
    box-sizing: border-box;
  }

  a {
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }

  /* antd global style overrides */
  .ant-btn-primary { box-shadow: none; }
  .ant-input-affix-wrapper, .ant-input-number, .ant-select, .ant-select-selector { box-shadow: none; }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-popover-content {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected { background-color: ${(
    props
  ) => (props.theme as any).colors.checkbox};}

  .ant-drawer {
    position: fixed;
  }

  .ant-notification-notice-wrapper {
    border: 1px solid ${(props) => (props.theme as any).colors.border};
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1) !important;
  }

  .ant-select-dropdown {
    border: 1px solid ${(props) => (props.theme as any).colors.border};

    &.ant-select-dropdown-placement-topLeft { border-bottom: none; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    &.ant-select-dropdown-placement-topRight { border-bottom: none; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    &.ant-select-dropdown-placement-bottomLeft { border-top: none; border-top-left-radius: 0; border-top-right-radius: 0; }
    &.ant-select-dropdown-placement-bottomRight { border-top: none; border-top-left-radius: 0; border-top-right-radius: 0; }
  }

  .ant-input-group-addon {
    // opacity: 0.5;
    color: ${(props) => (props.theme as any).colors.accent} !important;
  }
  .ant-table-placeholder {
    z-index: 0 !important;
  }

  // Mobile styles
  .ant-tour {
    max-width: fit-content
  }
  .mobile-layout {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;

    .sticky-search {
      position: sticky;
      top: 0;
      z-index: 998;
      padding: 10px 1em;
      border-bottom: 1px solid ${(props) => (props.theme as any).colors.border};
      background: ${(props) => (props.theme as any).colors.background};
    }
    
    .ant-drawer {
      width: 100% !important;
      max-width: 100% !important;
      
      .ant-drawer-content-wrapper {
        width: 100% !important;
        left: 0 !important;
        right: 0 !important;
      }
    }

    // Make dropdown buttons full width
    .ant-space-compact {
      width: 100%;
      display: flex;

    
      .ant-btn:first-child {
        flex: 1;
      }
    }

    // Stop all horizontal scroll
    .ant-layout,
    .ant-layout-content,
    .ant-layout-header,
    .ant-layout-footer {
      overflow-x: hidden;
      width: 100%;
      max-width: 100vw;
    }

    /* Ensure fixed elements don't cause horizontal scroll */
    .ant-drawer,
    .ant-modal,
    .ant-message,
    .ant-notification {
      max-width: 100vw;
    }
  }
  @media screen and (max-width: 768px) {
    input,
    select,
    textarea,
    .ant-input,
    .ant-select-selector,
    .ant-picker-input > input {
      font-size: 16px !important;
    }
  }


  // Print styles
  @media print {
    .hidden-print {
      display: none !important;
    }
    .ant-layout {
      margin-left: 0 !important;
    }
    body {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
`
