import { rem, darken } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialsListItemSectionMobileStyled = styled.span`
  font-size: ${rem(16)};

  .section-row {
    margin-top: 3em;
    padding-left: 10px;
    background-color: ${(props) => props.theme.colors.white};
    border-left: 2px solid ${(props) => props.theme.colors.accent};
    list-style-type: none;
  }
`
