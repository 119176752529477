import { rem, lighten, darken } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatesMaterialsFormListStyled = styled.div`
  font-size: ${rem(16)};

  .section-row {
    background-color: ${(props) => darken(0.0, props.theme.colors.background)};
  }
  .section-row > td {
    &:first-child {
      border-left: 5px solid ${(props) => props.theme.colors.accent};
    }
    .ant-typography {
      font-weight: bold;
    }
    // border-bottom-color: ${(props) => props.theme.colors.accent} !important;
  }
`
