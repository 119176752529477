import { ThunderboltFilled } from '@ant-design/icons'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { LogoStyled, LogoMobileStyled } from './styles'
export interface LogoProps {
  size?: string
  mode?: 'mobile' | 'desktop'
}

export const Logo = ({ mode, ...props }: LogoProps) => {
  return mode == 'mobile' ? (
    <LogoMobileStyled {...props}>
      <img src='/logo_medium.png' alt='logo' />
    </LogoMobileStyled>
  ) : (
    <LogoStyled {...props}>
      {/* <ThunderboltFilled style={{ fontSize: props.size == 'sm' ? 25 : 40 }} /> */}
      <img src='/logo_medium.png' alt='logo' />
    </LogoStyled>
  )
}

Logo.displayName = 'Logo'
